// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { AuthContextProvider } from './context/AuthContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <AuthContextProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
      </AuthContextProvider>
    </ThemeProvider>
  );
}
