import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoutes from './routes/PrivateRoute';
import AdminRoutes from './routes/AdminRoute';
import { UserAuth } from './context/AuthContext';

const LazyDashboardLayout = lazy(() => import('./layouts/dashboard'));
const LazyHome = lazy(() => import('./pages/Home'));
const LazyLogin = lazy(() => import('./pages/Login'));
const LazyDashboardApp = lazy(() => import('./pages/DashboardApp'));
const LazyBlog = lazy(() => import('./pages/Blog'));
const LazyUser = lazy(() => import('./pages/User'));
const LazyStudent = lazy(() => import('./pages/Student'));
const LazyNotFound = lazy(() => import('./pages/Page404'));
const LazyRegister = lazy(() => import('./pages/Register'));
const LazyProducts = lazy(() => import('./pages/Products'));
const LazyMyAccount = lazy(() => import('./pages/MyAccount'));
const LazySettings = lazy(() => import('./pages/Settings'));
const LazyLeagues = lazy(() => import('./pages/Leagues'));
const LazyAdmin = lazy(() => import('./pages/Admin'));
const LazyUserAdmin = lazy(() => import('./pages/UserAdmin'));
const LazyBlogAdmin = lazy(() => import('./pages/BlogAdmin'));
const LazyGuestbook = lazy(() => import('./pages/Guestbook'));
const LazyDiplomas = lazy(() => import('./pages/Diplomas'));
const LazySim = lazy(() => import('./pages/Simulate'));
const LazyGameshow = lazy(() => import('./pages/Gameshow'));
const LazyMindReader = lazy(() => import('./pages/MindReader'));
const LazyMindReaderAdmin = lazy(() => import('./pages/MindReaderAdmin'));
const LazyCodeMaster = lazy(() => import('./pages/CodeMaster'));
const LazyCodeMasterAdmin = lazy(() => import('./pages/CodeMasterAdmin'));

// const LazyJonatan = lazy(() => import('./pages/Jonatan'));

// ----------------------------------------------------------------------

export default function Router() {
 return (
  <>
   <Suspense fallback={<div>Laddar...</div>}>
    <Routes>
     <Route element={<PrivateRoutes />}>
      <Route element={<LazyDashboardLayout />} path="/">
       <Route element={<Navigate to="/hem" />} path="" exact />
       <Route element={<Navigate to="/hem" />} path="*" />
       <Route element={<LazyHome />} path="hem" exact />
       <Route element={<LazyDashboardApp />} path="tipset" exact />
       <Route element={<LazyUser />} path="tabell" exact />
       <Route element={<LazyProducts />} path="statistik" exact />
       <Route element={<LazyLeagues />} path="rivalkollen" exact />
       <Route element={<LazyBlog />} path="blogg" exact />
       <Route element={<LazyMyAccount />} path="mittkonto" exact />
       <Route element={<LazyGuestbook />} path="gastbok" exact />
       <Route element={<LazySettings />} path="installningar" exact />
       <Route element={<LazyDiplomas />} path="utmarkelser" exact />
       <Route element={<LazySim />} path="Simulera" exact />
       <Route element={<LazyGameshow />} path="gameshow" exact />
       <Route element={<LazyMindReader />} path="gameshow/mindreader" exact />
       <Route element={<LazyMindReaderAdmin />} path="gameshow/mindreader/admin" exact />
       <Route element={<LazyCodeMaster />} path="gameshow/codemaster" exact />
       <Route element={<LazyCodeMasterAdmin />} path="gameshow/codemaster/admin" exact />

       {/* <Route element={<LazyJonatan />} path="tipset/jonatan" exact /> */}

       <Route path="admin" element={<LazyAdmin />} exact />
       <Route path="admin/users" element={<LazyUserAdmin />} exact />
       <Route path="admin/blogg" element={<LazyBlogAdmin />} exact />
       <Route path="tabell/sbs" element={<LazyStudent />} exact />
      </Route>
     </Route>

     <Route path="*" element={<Navigate to="/register" />} />
     <Route path="/" element={<Navigate to="/register" />} />
     <Route path="/404" element={<LazyNotFound />} />
     <Route path="/login" element={<LazyLogin />} />
     <Route path="/register" element={<LazyRegister />} />
    </Routes>
   </Suspense>
  </>
 );
}
