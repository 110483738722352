import { useContext, createContext, useEffect, useState } from 'react';
import {
 createUserWithEmailAndPassword,
 signInWithEmailAndPassword,
 GoogleAuthProvider,
 signInWithRedirect,
 signOut,
 onAuthStateChanged,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
 const [user, setUser] = useState({});
 const [gameStatus, setGameStatus] = useState(false);
 const [timesUp, setTimesUp] = useState(false);
 const [gameActive, setGameActive] = useState(false);

 function signUpEmail(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
 }

 function logInEmail(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
 }

 const googleSignIn = () => {
  const provider = new GoogleAuthProvider();
  signInWithRedirect(auth, provider);
 };

 const logOut = () => {
  signOut(auth);
 };

 useEffect(() => {
  (async () => {
   const docRef = doc(db, 'gameStatus', 'areWeLive');
   const snapshot = await getDoc(docRef);
   if (snapshot.exists()) {
    setGameStatus(snapshot.data().status);
   } else {
    setGameStatus(false);
   }
  })();
 }, []);

 useEffect(() => {
  (async () => {
   const docRef = doc(db, 'gameStatus', 'areWeActive');
   const snapshot = await getDoc(docRef);
   if (snapshot.exists()) {
    setGameActive(snapshot.data().status);
    setTimesUp(snapshot.data().timesUp);
   } else {
    setGameActive(false);
    setTimesUp(false);
   }
  })();
 }, []);

 useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
   setUser(currentUser);
  });
  return () => {
   unsubscribe();
  };
 }, []);

 return (
  <AuthContext.Provider
   value={{
    signUpEmail,
    logInEmail,
    googleSignIn,
    logOut,
    user,
    gameStatus,
    gameActive,
    timesUp,
   }}
  >
   {children}
  </AuthContext.Provider>
 );
};

export const UserAuth = () => useContext(AuthContext);
